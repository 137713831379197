import React, { useEffect } from "react"
import { Box, CheckBox } from "grommet"

const GoodFastCheap = ({ po3 }) => {
  const [good, setGood] = React.useState(false)
  const [fast, setFast] = React.useState(false)
  const [cheap, setCheap] = React.useState(false)
  const [lastSet, setLastSet] = React.useState(0)



  useEffect(() => {
    let checker = [good, fast, cheap]
    if (checker.filter(Boolean).length > 2) {
      switch (lastSet) {
        case 1:
          setCheap(false)
          break
        case 2:
          setGood(false)
          break
        case 3:
          setFast(false)
          break
        default:
          setCheap(false)
      }
    }
  }, [ good, fast, cheap, lastSet])



  return (
    <Box
      background="light-1"
      pad={{ horizontal: "medium", vertical: "large" }}
      justify="around"
      gap="large"
      direction="row-responsive"
      margin={{ vertical: "medium" }}
    >
      <CheckBox
        style={{opacity: 1}}
        toggle
        disabled={po3 === true ? true : false}
        checked={po3 === true ? true : good}
        label="Good"
        onChange={event => {
          setGood(event.target.checked)
          setLastSet(1)
        }}
      />
      <CheckBox
        style={{opacity: 1}}
        toggle
        disabled={po3 === true ? true : false}
        checked={po3 === true ? true : fast}
        label="Fast"
        onChange={event => {
          setFast(event.target.checked)
          setLastSet(2)
        }}
      />
      <CheckBox
        style={{opacity: 1}}
        toggle
        disabled={po3 === true ? true : false}
        checked={po3 === true ? true : cheap}
        label="Cheap"
        onChange={event => {
          setCheap(event.target.checked)
          setLastSet(3)
        }}
      />
    </Box>
  )
}

export default GoodFastCheap
