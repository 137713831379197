import React from "react"
import { Helmet } from "react-helmet"
import { graphql, Link, navigate } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import styled from "styled-components"

import {
  Button,
  Heading,
  Paragraph,
  Box,
  Text,
  ResponsiveContext,
} from "grommet"
import { FormNext, FormPreviousLink, FormNextLink } from "grommet-icons"
import Seo from "./seo"
import SignUp from "./signup"
import GoodFastCheap from "./goodFastCheap"
import Layout from "./layout"
import theme from "../themes/po3Theme.json"
import "../static/assets/styles.css"

const shortcodes = {
  Link,
  SignUp,
  Paragraph,
  Heading,
  Button,
  GoodFastCheap,
  img: props => <img alt="" style={{ maxWidth: "100%" }} {...props} />,
  blockquote: props => (
    <blockquote
      style={{
        margin: "1em 0",
        borderLeft: `2px solid ${theme.global.colors.brand.light}`,
        padding: "0 0 0 1em",
      }}
    >
      {props.children}
    </blockquote>
  ),
  li: (props, i) => (
    <li key={i} style={{ marginBottom: "1em", lineHeight: "1.9rem" }}>
      {" "}
      {props.children}
    </li>
  ),
  p: props => <Paragraph style={{lineHeight: "1.9rem"}} fill {...props} />,
  h1: props => (
    <Heading level="1" size="medium" margin={{ top: "large", bottom: "medium" }}>
      {props.children}
    </Heading>
  ),
  h2: props => (
    <Heading level="2" size="small" margin={{ top: "large", bottom: "xsmall" }}>
      {props.children}
    </Heading>
  ),
  h3: props => (
    <Heading level="3" size="small" margin={{ top: "large", bottom: "xsmall" }}>
      {props.children}
    </Heading>
  ),
  h4: props => (
    <Heading level="4" size="small" margin={{ top: "large", bottom: "xsmall" }}>
      {props.children}
    </Heading>
  ),
} // Provide common components here

const BookNav = styled(Box)`
  box-sizing: border-box;
  transition: all 0.4s cubic-bezier(.25,.8,.25,1);
  :hover {
    box-shadow: 0 0 0 2px ${theme.global.colors.brand.light};
    color: ${theme.global.colors.brand.light};
  }
`

export default function Post({ ...data }) {
  return (
    <Layout>
      <Helmet>
        <script type="application/ld+json">
          {`{
         "@context": "https://schema.org",
         "@type": "NewsArticle",
         "headline": "${data.data.post.frontmatter.title}",
         "image": ["https://po3.ventures${data.data.post.frontmatter.image.publicURL}"]
       }`}
        </script>
      </Helmet>
      <Seo title={data.data.post.frontmatter.title} />
      <Box>
        <Box
          width="large"
          alignSelf="center"
          fill="vertical"
          margin={{ bottom: "large" }}
          pad={{ horizontal: "medium" }}
        >
          <Text>
            <Link to="/journal">Journal</Link>
            <FormNext margin={{ left: "small" }} size="small" color="dark-2" />
          </Text>
          <MDXProvider components={shortcodes}>
            <MDXRenderer>{data.data.post.body}</MDXRenderer>
          </MDXProvider>
          <Box
            margin={{ top: "large" }}
            border={{ side: "top", color: "brand" }}
            pad={{ top: "medium" }}
          >
            <Paragraph color="dark-1">
              Liked this? Share it on{" "}
              <a
                rel="noreferrer"
                target="_blank"
                href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                  "https://po3.ventures" + data.uri
                )}`}
              >
                LinkedIn
              </a>{" "}
              or{" "}
              <a
                rel="noreferrer"
                target="_blank"
                href={`https://twitter.com/intent/tweet?text=Check%20this%20out:%20${encodeURIComponent(
                  "https://po3.ventures" + data.uri
                )}`}
              >
                Twitter
              </a>
            </Paragraph>
          </Box>
          <Box margin={{ vertical: "medium" }}>
            {data.data.post.frontmatter.type === "book" && (
              <ResponsiveContext.Consumer>
                {size => (
                  <Box direction="row-responsive" gap="small">
                    {data.data.previous && (
                      <BookNav
                        basis={data.data.next ? "1/2" : "full"}
                        onClick={() => {
                          navigate(data.data.previous.fields.slug)
                        }}
                        fill="vertical"
                        justify="between"
                        align="center"
                        direction="row"
                        pad="medium"
                        gap="medium"
                        elevation="small"
                        round="xxsmall"
                      >
                        <FormPreviousLink />
                        <Box gap="xxsmall" direction="column" wrap="false">
                          <Text textAlign="end" size="xsmall" color="dark-3">
                            Previous
                          </Text>
                          <Text
                            textAlign="end"
                            size="small"
                            weight={500}
                            truncate
                          >
                            {data.data.previous.frontmatter.title}
                          </Text>
                        </Box>
                      </BookNav>
                    )}
                    {data.data.next && (
                      <BookNav
                        basis={data.data.previous ? "1/2" : "full"}
                        fill="vertical"
                        onClick={() => {
                          navigate(data.data.next.fields.slug)
                        }}
                        justify="between"
                        align="center"
                        direction="row"
                        pad="medium"
                        gap="medium"
                        elevation="small"
                        round="xxsmall"
                      >
                        <Box gap="xxsmall" direction="column" wrap="false">
                          <Text textAlign="start" size="xsmall" color="dark-3">
                            Next
                          </Text>
                          <Text
                            textAlign="start"
                            size="small"
                            truncate
                            weight={500}
                          >
                            {data.data.next.frontmatter.title}
                          </Text>
                        </Box>
                        <FormNextLink />
                      </BookNav>
                    )}
                  </Box>
                )}
              </ResponsiveContext.Consumer>
            )}
          </Box>
        </Box>
      </Box>
      <Box background="background-contrast">
        <Box
          width="large"
          alignSelf="center"
          pad={{ vertical: "large", horizontal: "medium" }}
        >
          <Heading margin={{ bottom: "none" }} level="2" size="medium">
            Sign up for purpose
          </Heading>
          <Paragraph margin={{ bottom: "large" }} size="large">
            We're writing a book, a 'how-to' for the design and delivery of
            purpose driven, successful businesses.
            <br />
            Then <strong>we're giving it away, so anyone can use it</strong>.
          </Paragraph>
          <SignUp color="brand" />
        </Box>
      </Box>
    </Layout>
  )
}
export const pageQuery = graphql`
  query BlogPostQuery($id: String, $next: String, $previous: String) {
    post: mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        type
        image {
          publicURL
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    }
    next: mdx(id: { eq: $next }) {
      id
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    previous: mdx(id: { eq: $previous }) {
      id
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
